<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="渠道名称">
          <el-input v-model="searchData.channelName" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="日期" class="item">
          <el-date-picker
            style="width:280px;"
            v-model="searchData.timeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="handle-btn">
      <div></div>
      <el-button v-checkbtn="isShowBtn(AUTH_BTN.channel_count_export)" type="primary" icon="el-icon-download" size="small" @click="handleExport"
        >导出</el-button
      >
    </div>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { DownloadAPI, getListAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin.js'
const columns = [
  {
    label: '渠道名称',
    prop: 'channelName',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['channelName']}</p>
    }
  },
  {
    label: '日期',
    prop: 'date',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['date']}</p>
    }
  },

  {
    label: '注册量',
    prop: 'registerNum',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['registerNum']}</p>
    }
  },
  {
    label: '订单申请量',
    prop: 'orderApplyNum',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['orderApplyNum']}</p>
    }
  },
  {
    label: '支付量',
    prop: 'orderPayNum',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['orderPayNum']}</p>
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        channelName: '',
        timeSlot: []
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { timeSlot, channelName } = this.searchData
      let params = { page: this.currentPage, pageSize: this.pageSize, channelName }
      if (timeSlot && timeSlot.length > 0) {
        params.leftDate = timeSlot[0]
        params.rightDate = timeSlot[1]
      }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    handleExport() {
      const { timeSlot, channelName } = this.searchData
      let params = { channelName }
      if (timeSlot && timeSlot.length > 0) {
        params.leftDate = timeSlot[0]
        params.rightDate = timeSlot[1]
      }
      DownloadAPI(params)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .el-divider--horizontal {
    margin: 0 0 20px;
  }

  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 15px;
        .el-select,
        .el-input {
          width: 280px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 30%;
          }
        }
      }
      .keywords {
        .el-select .el-input {
          width: 100px;
        }

        .el-select {
          width: 100px;
        }
        .input-with-select {
          vertical-align: middle;
        }
        .input-with-select .el-input-group__prepend {
          background-color: #fff;
        }
      }
    }
  }

  .handle-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
